import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Psychotherapie',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'Über mich',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/kip',
    name: 'KIP',
    component: () => import(/* webpackChunkName: "about" */ '../views/KIP.vue')
  },
  {
    path: '/tfp',
    name: 'TFP',
    component: () => import(/* webpackChunkName: "about" */ '../views/TFP.vue')
  },
  {
    path: '/topic',
    name: 'TOPIC',
    component: () => import(/* webpackChunkName: "about" */ '../views/TOPIC.vue')
  },
  {
    path: '/echo',
    name: 'ECHO',
    component: () => import(/* webpackChunkName: "about" */ '../views/ECHO.vue')
  },
  {
    path: '/arbeitsweise',
    name: 'Arbeitsweise',
    component: () => import(/* webpackChunkName: "about" */ '../views/Arbeitsweise.vue')
  },
  {
    path: '/themen',
    name: 'Themen',
    component: () => import(/* webpackChunkName: "about" */ '../views/Themen.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "about" */ '../views/Kontakt.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "about" */ '../views/Datenschutz.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
