import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'

const app = createApp(App).use(router).use(router).mount('#app')

router.beforeEach((to, from, next) => {
    $('.mobile-menu').fadeOut()
    $('.loader').show()
    $('.transition--active').each((i, e) => {
        $(e).removeClass('transition--active')
        $(e).addClass('transition--fade')
    })
    setTimeout(() => {
        next()
    }, 500)
})

router.afterEach((to) => {
    document.title = 'Martin Haltrich - ' + to.name?.split('View')?.[0]
    app.$nextTick(() => {
        $('.loader').hide()
        $('.transition--fade').each((i, e) => {
            $(e).removeClass('transition--fade')
            $(e).addClass('transition--active')
        })
    })
})
