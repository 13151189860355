<template>
    <nav @click="closeMenu()">
        <ul class="navigation">
            <li>
                <router-link to="/">
                    <span class="underline active">
                        Psychotherapie
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/kip">
                    <span class="underline active">
                        KIP
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/tfp">
                    <span class="underline active">
                        TFP
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/topic">
                    <span class="underline active">
                        TOPIC
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/echo">
                    <span class="underline active">
                        ECHO
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/themen">
                    <span class="underline active">
                        Themen
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/arbeitsweise">
                    <span class="underline active">
                        Arbeitsweise
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/about">
                    <span class="underline active">
                        Über mich
                    </span>
                </router-link>
            </li>
            <li>
                <router-link to="/kontakt">
                    <span class="underline active">
                        Kontakt
                    </span>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'MenuComponent',
  methods: {
    closeMenu () {
      $('.mobile-menu').fadeOut()
    }
  }
}
</script>
